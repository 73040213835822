html {
  background-color: #edda9e;
}

.App {
  background: 
    repeating-radial-gradient( circle at 0 0, solid 0, #d5c1fa 36px ), 
    repeating-linear-gradient( #e66cf100, #e66cf100 ); 
  /* background */
  /* background: rbg(213, 193, 250); */
  text-align: center;
  align-content: center;
  width: 100vw;
  font-family: 'DM Sans', sans-serif;
  margin: 0;
  z-index: -1;
}

.title {
  font-weight: 700;
  font-size: calc(20px + 2vmin);
  padding: 16px 8px 16px 8px;
}

.subtitle {
  font-weight: 400;
  font-size: calc(8px + 2vmin);
  padding: 0px 8px 0px 8px;
}

.subtitle2 {
  font-weight: 400;
  font-size: calc(4px + 2vmin);
  padding: 4px;
}

.App-logo {
  height: 20vmin;
  border-radius: 200px;
  pointer-events: none;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3); 
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
}

.icons {
  display: flex;
}

.glass-card {
  align-items: center;
  flex-direction: column;
  display: flex;
  margin: 20px;
  backdrop-filter: blur(12px) saturate(180%);
  -webkit-backdrop-filter: blur(12px) saturate(180%);
  padding-top: 28px;
  padding-right: 28px;
  padding-left: 28px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, .2);
  border: 1px solid rgba(209, 213, 219, 0.3);
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.2); 
  box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.2);
}  

.button {
  margin: auto;
}

a {
  text-decoration: none;
  color: #333;
  width: 100%;
  margin: auto;
}

.neomorphism {
  border-radius: 50px;
  border: 1px solid rgba(209, 213, 219, .3);
  background-color: rgba(255, 255, 255, .4);
  display: flex; /* vertical align */
  width: 100%;
  padding: 0vmin;
  height: calc(20px + 4vmin);
  font-size: calc(8px + 2vmin);
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1); 
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
  backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
}

.neomorphism:hover {
  cursor: pointer;
  border: 1px solid rgba(209, 213, 219, 0.3);
  background-color: rgba(255, 255, 255, .6);
}

.spacing {
  padding: 16px;
}

.medium-spacing {
  padding: 12px;
}

.button-spacing {
  padding: 8px;
}

.small-spacing {
  padding: 6px;
}

.header-spacing {
  padding: 4px;
}

.tiny-spacing {
  padding: 2px;
}

/* Animated Circles CSS */

div {
	--theme1purple: rgb(213,193,250);	/* background */
	--theme1pinkLight: #FAD1FA; 		/* 0, 1, 7 */
	--theme1teal: #99f1fa; 				/* 2, 4, 5, 10 */
	--theme1idk: #a7fac6; 				/* 3, 6 */
	--theme1huh: #faf8c5; 				/* 8, 9 */
}

div {
	--theme2blue: rgb(0, 193, 250);	/* background */
	--theme2pinkLight:  #FAD1FA; 	/* 0, 1 */
	--theme2teal: #99f1fa; 			/* 2, 5, 10 */
	--theme2purpleLight: #c0a7fa; 	/* 3, 6 */
	--theme2purpleDark: #b399fa; 	/* 4 */
	--theme2pinkDark:  #fcc6fc; 	/* 7 */
	--theme2green: #c5faf7; 		/* 8, 9 */
}

div {
	--theme3yellow: #edda9e; 	/* background */
	--theme3red: #f16a3c;		/* 0, 1 */
	--theme3orange: #f7892c;	/* 2, 5, 10 */
	--theme3pink: #ecc0ab;		/* 3, 6 */
	--theme3peach: #f69273;		/* 4, 7 */
	--theme3gold: #f5ba3d;		/* 8, 9 */
}

.circles {
  background-color: var(--theme3yellow); 
	position: absolute;
	width: 100%;
  height: 100vh;
	z-index: -1;
}

.circles li {
	position: absolute;
	display: block;
	list-style: none;
	width: 80px;
	height: 80px;
	background: var(--theme3red);
	animation: animate 25s linear infinite;
	bottom: calc(100vh);
}

.circles li:nth-child(1) {
	left: 0%;
	width: 320px;
	height: 320px;
	animation-delay: 0s;
	animation-duration: 10s;
	border-radius: 50%;
	background: var(--theme3red);
}

.circles li:nth-child(2) {
	left: 10%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
	animation-duration: 12s;
	border-radius: 50%;
	background: var(--theme3orange);
}

.circles li:nth-child(3) {
	left: 70%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
	animation-duration: 10s;
	border-radius: 50%;
	background: var(--theme3pink);
}

.circles li:nth-child(4) {
	left: 40%;
	width: 240px;
	height: 240px;
	animation-delay: 0s;
	animation-duration: 7s;
	border-radius: 50%;
	background:  var(--theme3peach);
}

.circles li:nth-child(5) {
	left: 65%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
	animation-duration: 10s;
	border-radius: 50%;
	background: var(--theme3orange);
}

.circles li:nth-child(6) {
	left: 5%;
	width: 360px;
	height: 360px;
	animation-delay: 0s;
	animation-duration: 12s;
	border-radius: 50%;
	background: var(--theme3pink);
}

.circles li:nth-child(7) {
	left: 35%;
	width: 200px;
	height: 200px;
	animation-delay: 0s;
	animation-duration: 15s;
	border-radius: 50%;
	background: var(--theme3peach);
}

.circles li:nth-child(8) {
	left: 60%;
	width: 100px;
	height: 100px;
	animation-delay: 0s;
	animation-duration: 17s;
	border-radius: 50%;
	background: var(--theme3gold);
}

.circles li:nth-child(9) {
	left: 20%;
	width: 60px;
	height: 60px;
	animation-delay: 0s;
	animation-duration: 20s;
	border-radius: 50%;
	background:  var(--theme3gold);
}

.circles li:nth-child(10) {
	left: 54%;
	width: 200px;
	height: 200px;
	animation-delay: 0s;
	animation-duration: 22s;
	border-radius: 50%;
	background: var(--theme3orange);
}

@keyframes animate {
	0% {
		transform: translateY(5vh) rotate(0deg);
		opacity: 1;
	}
	50% {
		transform: translateY(100vh) rotate(360deg);
		opacity: 0.05;
	}
	100% {
		transform: translateY(5vh) rotate(720deg);
		opacity: 1;
	}
}